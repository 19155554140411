import { IJodit } from '@/modules/CampaignsModule/interfaces/HTMLEditor';

interface WindowWithTitleValue extends Window {
  __lastTitleValue?: string;
}

interface JoditDialog {
  // cspell:disable-next-line
  dialogbox: HTMLElement;
  close: () => void;
}

export const joditLinkBuilder = (editor: IJodit) => {
  const i18n = editor.i18n.bind(editor);
  return `<form class="jodit-form">
            <div class="jodit-form__group">
              <label>${i18n('URL')}</label>
              <input ref="url_input" class="jodit-input" required/>
            </div>
            <div class="jodit-form__group">
              <label>${i18n('Text')}</label>
              <input ref="content_input" class="jodit-input"/>
            </div>
            <div class="jodit-form__group" style="display: none;">
              <label>${i18n('ClassName')}</label>
              <input ref="className_input" class="jodit-input"/>
            </div>
            <div class="jodit-form__group">
              <label>${i18n('Name link')}</label>
              <input ref="title_input" class="jodit-input" data-ref="title_input" />
            </div>
            <div class="jodit-form__group">
              <label class="jodit-checkbox">
                <input ref="target_checkbox" type="checkbox" class="jodit-checkbox__input"/>
                <span class="jodit-checkbox__box"></span>
                <span class="jodit-checkbox__label">${i18n('Open new tab')}</span>
              </label>
            </div>
            <div class="jodit-form__group">
              <label class="jodit-checkbox">
                <input ref="nofollow_checkbox" type="checkbox" class="jodit-checkbox__input"/>
                <span class="jodit-checkbox__box"></span>
                <span class="jodit-checkbox__label">${i18n('No follow')}</span>
              </label>
            </div>
            <div class="jodit-button-group jodit-button-group__dialog">
              <button type="button" ref="unlink" class="jodit-button">${i18n('Unlink')}</button>
              <button type="submit" ref="insert" class="jodit-button jodit-button_status_success">${i18n(
                'Save'
              )}</button>
            </div>
          </form>
        `;
};

export const joditLinkBuilderEvents = (editor: IJodit) => {
  let lastTitleValue = '';
  const customWindow = window as WindowWithTitleValue;

  if (editor.link) {
    const originalOpen = editor.link.open;

    const setTitleInputValue = (title: string) => {
      const dialogs = document.querySelectorAll('.jodit-dialog');
      dialogs.forEach((dialog) => {
        const form = dialog.querySelector('form');
        if (form) {
          const titleInputs = form.querySelectorAll(
            'input[ref="title_input"], input[data-ref="title_input"]'
          );
          titleInputs.forEach((input: Element) => {
            (input as HTMLInputElement).value = title;

            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
          });
        }
      });
    };

    editor.link.open = function (this: NonNullable<IJodit['link']>, selectionText?: string) {
      const selected = editor.s.current();
      let linkBeforeOpen: Element | null = null;
      let titleBeforeOpen = '';

      if (selected) {
        if (selected.nodeName === 'A') {
          linkBeforeOpen = selected;
        } else if (selected.parentElement && selected.parentElement.nodeName === 'A') {
          linkBeforeOpen = selected.parentElement;
        }

        if (linkBeforeOpen) {
          titleBeforeOpen = linkBeforeOpen.getAttribute('title') || '';
          lastTitleValue = titleBeforeOpen;
          customWindow.__lastTitleValue = titleBeforeOpen;
        }
      }

      originalOpen.call(this, selectionText);

      setTimeout(() => {
        if (titleBeforeOpen) {
          setTitleInputValue(titleBeforeOpen);
        }

        const dialogInstance = editor.getInstance('Dialog') as JoditDialog;
        // cspell:disable-next-line
        if (!dialogInstance || !dialogInstance.dialogbox) {
          return;
        }
        // cspell:disable-next-line
        const form = dialogInstance.dialogbox.querySelector('form');
        if (!form) {
          return;
        }

        const selected = editor.s.current();
        let link: Element | Node | null = null;

        if (selected) {
          if (selected.nodeName === 'A') {
            link = selected;
          } else if (selected.parentElement && selected.parentElement.nodeName === 'A') {
            link = selected.parentElement;
          } else if (selected.parentNode && (selected.parentNode as HTMLElement).nodeName === 'A') {
            link = selected.parentNode;
          } else {
            const selection = editor.selection.sel;
            if (selection && selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              const container = range.commonAncestorContainer;

              if (container.nodeName === 'A') {
                link = container;
              } else if (container.parentElement && container.parentElement.nodeName === 'A') {
                link = container.parentElement;
              } else if (
                container.parentNode &&
                (container.parentNode as HTMLElement).nodeName === 'A'
              ) {
                link = container.parentNode;
              }
            }
          }
        }

        if (link) {
          const allAttrs: Record<string, string> = {};
          Array.from((link as HTMLElement).attributes).forEach((attr) => {
            allAttrs[attr.name] = attr.value;
          });

          const title = (link as HTMLElement).getAttribute('title') || '';

          const titleInputs = form.querySelectorAll(
            'input[ref="title_input"], input[data-ref="title_input"]'
          );

          titleInputs.forEach((input: Element) => {
            (input as HTMLInputElement).value = title;

            const event = new Event('input', { bubbles: true });
            input.dispatchEvent(event);
          });

          lastTitleValue = title;
          customWindow.__lastTitleValue = title;
        }
      }, 100);
    };

    editor.link.insert = function (
      this: NonNullable<IJodit['link']>,
      url: string,
      text: string,
      target: boolean,
      className: string,
      nofollow: boolean
    ) {
      const dialog = editor.getInstance('Dialog') as JoditDialog;

      // cspell:disable-next-line
      const form = dialog.dialogbox?.querySelector('form');

      let title = '';
      if (form) {
        const titleInput = form.querySelector('[ref="title_input"]') as HTMLInputElement;
        if (titleInput) {
          title = titleInput.value;
          lastTitleValue = title;
          customWindow.__lastTitleValue = title;
        }
      }

      const link = editor.create.inside.element('a') as HTMLAnchorElement;

      link.setAttribute('href', url);

      if (text) link.innerHTML = text;

      if (target) link.setAttribute('target', '_blank');

      if (nofollow) link.setAttribute('rel', 'nofollow');

      if (title) link.setAttribute('title', title);

      editor.s.insertNode(link);

      dialog.close();

      return link;
    };
  }

  editor.events.on(
    'beforeInsertLink',
    (link: HTMLAnchorElement, attrs: Record<string, string | null>) => {
      const dialog = editor.getInstance('Dialog') as JoditDialog;
      // cspell:disable-next-line
      const form = dialog.dialogbox?.querySelector('form');

      if (form) {
        const titleInput = form.querySelector('[ref="title_input"]') as HTMLInputElement;
        if (titleInput && titleInput.value) {
          attrs.title = titleInput.value;
        }
      }

      return [link, attrs];
    }
  );

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeName === 'A') {
            const link = node as HTMLAnchorElement;

            if (link.hasAttribute('class') && !link.hasAttribute('title')) {
              const className = link.getAttribute('class');
              if (className) {
                link.setAttribute('title', className);
                link.removeAttribute('class');
              }
            }

            if (!link.hasAttribute('title') && customWindow.__lastTitleValue) {
              link.setAttribute('title', customWindow.__lastTitleValue);
            }
          }
        });
      }
    });
  });

  if (editor.editor) {
    observer.observe(editor.editor, { childList: true, subtree: true });
  } else if (editor.element) {
    observer.observe(editor.element, { childList: true, subtree: true });
  }

  editor.events.on('change', () => {
    const links = editor.editor.querySelectorAll('a:not([type="widgetNps"])');

    links.forEach((link) => {
      const anchorLink = link as HTMLAnchorElement;
      if (anchorLink.hasAttribute('class') && !anchorLink.hasAttribute('title')) {
        const className = anchorLink.getAttribute('class');
        if (className) {
          anchorLink.setAttribute('title', className);
          anchorLink.removeAttribute('class');
        }
      }

      if (anchorLink.hasAttribute('title_input')) {
        const titleInput = anchorLink.getAttribute('title_input');
        if (titleInput) {
          anchorLink.setAttribute('title', titleInput);
          anchorLink.removeAttribute('title_input');
        }
      }

      if (anchorLink.hasAttribute('className_input')) {
        const classNameInput = anchorLink.getAttribute('className_input');
        if (classNameInput) {
          anchorLink.setAttribute('title', classNameInput);
          anchorLink.removeAttribute('className_input');
        }
      }

      if (!anchorLink.hasAttribute('title') && customWindow.__lastTitleValue) {
        anchorLink.setAttribute('title', customWindow.__lastTitleValue);
      }
    });
  });

  editor.events.on('beforeOpenLinkDialog', () => {
    setTimeout(() => {
      const selected = editor.s.current();
      let link: Element | null = null;

      if (selected && selected.nodeName === 'A') {
        link = selected;
      } else if (selected && selected.parentElement && selected.parentElement.nodeName === 'A') {
        link = selected.parentElement;
      } else if (
        selected &&
        selected.parentNode &&
        (selected.parentNode as HTMLElement).nodeName === 'A'
      ) {
        link = selected.parentNode as Element;
      }

      if (link) {
        const title = (link as HTMLElement).getAttribute('title') || '';

        const dialog = document.querySelector('.jodit-dialog');
        if (dialog) {
          const form = dialog.querySelector('form');
          if (form) {
            const titleInput = form.querySelector('[ref="title_input"]') as HTMLInputElement;

            if (titleInput) {
              titleInput.value = title;

              lastTitleValue = title;
              customWindow.__lastTitleValue = title;
            }
          }
        }
      }
    }, 100);
  });

  editor.events.on('afterOpenDialog', (dialog: JoditDialog) => {
    // cspell:disable-next-line
    if (dialog && dialog.dialogbox) {
      // cspell:disable-next-line
      const form = dialog.dialogbox.querySelector('form');
      if (form) {
        const titleInput = form.querySelector('[ref="title_input"]') as HTMLInputElement;
        if (titleInput) {
          const selected = editor.s.current();
          let link: Element | null = null;

          if (selected && selected.nodeName === 'A') {
            link = selected;
          } else if (
            selected &&
            selected.parentElement &&
            selected.parentElement.nodeName === 'A'
          ) {
            link = selected.parentElement;
          } else if (
            selected &&
            selected.parentNode &&
            (selected.parentNode as HTMLElement).nodeName === 'A'
          ) {
            link = selected.parentNode as Element;
          }

          if (link) {
            const title = (link as HTMLElement).getAttribute('title') || '';

            titleInput.value = title;

            lastTitleValue = title;
            customWindow.__lastTitleValue = title;
          }

          titleInput.addEventListener('input', (e) => {
            const value = (e.target as HTMLInputElement).value;

            lastTitleValue = value;
            customWindow.__lastTitleValue = value;
          });
        }
      }
    }
  });

  editor.events.on('afterCloseDialog', () => {
    setTimeout(() => {
      const links = editor.editor.querySelectorAll('a:not([type="widgetNps"])');

      links.forEach((link) => {
        const anchorLink = link as HTMLAnchorElement;
        if (!anchorLink.hasAttribute('title') && lastTitleValue) {
          anchorLink.setAttribute('title', lastTitleValue);
        }

        if (!anchorLink.hasAttribute('title') && customWindow.__lastTitleValue) {
          anchorLink.setAttribute('title', customWindow.__lastTitleValue);
        }
      });
    }, 100);
  });

  const originalInsertLink = editor.selection.insertLink;
  if (originalInsertLink) {
    editor.selection.insertLink = function (
      url: string,
      text?: string,
      target?: string,
      title?: string
    ) {
      if (customWindow.__lastTitleValue) {
        title = customWindow.__lastTitleValue;
      }

      const result = originalInsertLink.call(this, url, text, target, title);

      setTimeout(() => {
        const links = editor.editor.querySelectorAll('a');
        const lastLink = links[links.length - 1];
        if (lastLink && !lastLink.hasAttribute('title') && title) {
          lastLink.setAttribute('title', title);
        }
      }, 0);

      return result;
    };
  }
};
