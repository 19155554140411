/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
// cspell:disable-next-line
import BeePlugin from '@mailupinc/bee-plugin';
import {
  BeePluginError,
  BeePluginMessageEditDetail,
  IBeeConfig,
  IEntityContentJson,
  IPluginRow,
} from '@mailupinc/bee-plugin/dist/types/bee';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import configData from '@/src/config.json';
import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import TemplateAdapter, {
  ILinkTemplateV1,
  replaceEmojis,
} from '@/src/modules/CampaignsModule/adapters/TemplateAdapter';
import {
  atomBeefreeAuthorize,
  atomBeefreeDisabled,
  atomBeefreePreview,
  atomBeefreePristine,
  atomBeefreeSaved,
  atomBeefreeSaving,
  atomEditorMail,
  atomModalTemplate,
  atomTemplateDataSave,
  EditorMailData,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { BEEFREE_INTERVAL_START } from '@/src/modules/CampaignsModule/constants';
import {
  BeefreeLanguages,
  IAuthBeefree,
  IBeefreeRowType,
  IRowSaveData,
  ISaveMail,
  ISaveRowError,
  ISpecialLink,
  IUseEditor,
  IUseServicesEditor,
} from '@/src/modules/CampaignsModule/interfaces/Beefree';
import {
  BEEFREE_CUSTOM_FONTS,
  BEEFREE_LANGUAGES,
} from '@/src/modules/CampaignsModule/utils/beefree';

import { useBeefreeLogger } from './useBeefreeLogger';
import useContentDialog from './useContentDialog';
import { usePreventExit } from './usePreventExit';
import useRows from './useRows';

import { atomNewRulePath } from '@/modules/RulesModule/atoms/rules';

export const useEditor = ({
  onSaveRow,
  onSaveMail,
  onSend,
  onSaveTemplate,
  onRemoveRow,
  accountId,
  listCustomField,
  tags,
  signature,
}: IUseEditor) => {
  const ref = useRef<BeePlugin>();
  const { t, i18n } = useTranslation();
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [initialized, setInitialized] = useAtom(atomBeefreeAuthorize);
  const [disabled, setDisabled] = useAtom(atomBeefreeDisabled);
  const saving = useAtomValue(atomBeefreeSaving);
  const [saved, setSaved] = useAtom(atomBeefreeSaved);
  const [pristine, setPristine] = useAtom(atomBeefreePristine);
  const setShowPreview = useSetAtom(atomBeefreePreview);
  const [mailContent, setMailContent] = useAtom(atomEditorMail);
  const { getRows, closeSaveRow } = useRows();
  const { loadModal } = useContentDialog();
  const { logger } = useBeefreeLogger();
  const { getRedirectPrevent, removePreventRedirect } = usePreventExit();

  const mergeTags = useMemo(() => {
    return listCustomField?.map((item) => {
      const { name, emblueField, number } = item;
      const slug = `\${${number}#${name}}`;
      const previewValue = emblueField
        ? t(`EDITOR_CONTENT.DEFAULT_FIELDS_VALUES.${name.toLocaleLowerCase().replaceAll(' ', '_')}`)
        : '';
      return {
        name: name, // Name in view
        value: slug, // Value in html
        previewValue: previewValue, // Preview toggle merge tag
      };
    });
  }, [listCustomField, t]);

  const specialLinks: ISpecialLink[] = useMemo(() => {
    const result: ISpecialLink[] = [];

    Array.isArray(mergeTags) &&
      listCustomField.forEach((item) => {
        const number: string = item.number.toString();
        const name: string = item.name;
        result.push({
          type: 'Custom Fields',
          label: name,
          link: '${' + number + '#' + name + '}',
        });
      });

    Array.isArray(signature) &&
      signature?.forEach((item) => {
        result.push({
          label: item.label,
          type: 'Signature',
          link: item.link,
        });
      });

    return result;
  }, [listCustomField, mergeTags, signature]);

  const handleGetRows = useCallback(
    async (
      resolve: (item: IPluginRow[]) => void,
      _reject: () => void,
      args: IPluginRow[]
    ): Promise<void> => {
      if (!('handle' in args)) return;
      const handle = args.handle as string;
      const rows = await getRows(handle);
      rows && resolve(rows);
    },
    [getRows]
  );

  const handleSaveRow = useCallback(
    async (
      resolve: (item: { name: string; guid: string }, extra: { synced: boolean }) => void,
      reject: () => void,
      row: IPluginRow
    ): Promise<void> => {
      // Save New Row
      const modalResult = await loadModal();
      const { name = '', synced = false, isEmblueRow = false } = modalResult;

      const rowSave = {
        ...row,
        metadata: {
          ...(row.metadata ?? {}),
          name,
        },
      };

      const replaced = replaceEmojis(JSON.stringify(rowSave));

      const response = await onSaveRow({ name, isEmblueRow }, JSON.parse(replaced));

      if (response && name) {
        const guid = crypto.randomUUID();
        await logger('saveRow', 'action', {
          name,
          guid,
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        resolve({ name, guid }, { synced });
      } else {
        await logger('saveRow', 'error', {
          name,
          row,
        });
        reject();
      }
    },
    [loadModal, logger, onSaveRow]
  );

  const getToken = useCallback(async () => {
    const clientId: string = configData?.endpoints?.[env]?.BEEFREE_CLIENT_ID;
    const clientSecret: string = configData?.endpoints?.[env]?.BEEFREE_CLIENT_SECRET;
    const payload: IAuthBeefree = {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'password',
    };
    ref.current = new BeePlugin();
    await ref?.current?.getToken(payload.client_id, payload.client_secret);
  }, [env]);

  const handleOnError = useCallback(
    async (error: BeePluginError) => {
      const { code, detail, message, data } = error;

      await logger('onError', 'error', {
        code,
        detail,
        message,
        data,
      });

      if (code === 5101) {
        void getToken();
      } else if (code === 5102 && mailContent) {
        void getToken();
        void start(mailContent?.contentHtmlEditable);
      }
    },
    [getToken, mailContent]
  );

  const configuration: IBeeConfig = useMemo(() => {
    const defaultOption = ['none'];
    const mergeContents = signature.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const name: string = item.label.substring(1, item.label.length - 1);
      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        name: name,
        value: item.link,
      };
    });
    return {
      uid: accountId.toString(),
      container: 'bee-plugin-container',
      language: BEEFREE_LANGUAGES[i18n.language as BeefreeLanguages].code,
      enable_display_conditions: false,
      preventClose: false,
      trackChanges: true,
      saveRows: true,
      translations: BEEFREE_LANGUAGES[i18n.language as BeefreeLanguages].translations,
      loadingSpinnerDisableOnSave: true,
      loadingSpinnerDisableOnDialog: true,
      editorFonts: {
        showDefaultFonts: true,
        customFonts: BEEFREE_CUSTOM_FONTS,
      },
      rowsConfiguration: {
        emptyRows: true,
        defaultRows: false,
        externalContentURLs: [
          {
            name: t('EDITOR_CONTENT.ROWS.byDefault'),
            value: 'byDefault',
            handle: 'byDefault',
            isLocal: true,
            behaviors: {
              canEdit: false,
              canDelete: false,
              canDeleteSyncedRows: false,
              canEditSyncedRows: false,
            },
          },
          {
            name: t('EDITOR_CONTENT.ROWS.savedUser'),
            value: 'saved-user',
            handle: 'saved-user',
            isLocal: true,
            behaviors: {
              canEdit: false,
              canDelete: true,
              canDeleteSyncedRows: false,
              canEditSyncedRows: false,
            },
          },
        ],
      },
      customAttributes: {
        attributes: [
          {
            key: 'custom-fields',
            value: defaultOption.concat(mergeTags?.map(({ name }) => name) ?? []),
            target: 'link',
          },
          {
            key: 'tag',
            value: defaultOption.concat(tags?.map((tag) => tag.name) ?? []),
            target: 'link',
          },
          {
            key: 'signature',
            value: defaultOption.concat(signature?.map(({ label }) => label) ?? []),
            target: 'link',
          },
          {
            key: 'title',
            target: 'link',
          },
        ],
      },
      onSave: onSaveMail,
      onSend,
      onLoad: () => setDisabled(false),
      onSaveAsTemplate: onSaveTemplate,
      onError: handleOnError,
      onTogglePreview: setShowPreview,
      onSaveRow: () => closeSaveRow(),
      ...{ mergeTags },
      specialLinks,
      mergeContents,
      contentDialog: {
        onDeleteRow: {
          handler: async (resolve, reject, args) => {
            try {
              const { row } = args as { row: IPluginRow };
              const rowId: string = (row?.metadata?.guid as string) ?? '';
              await onRemoveRow(rowId);
              resolve(true);
            } catch (error) {
              reject();
            }
          },
        },
        saveRow: {
          handler: handleSaveRow,
        },
      },
      onChange: (_json: string, _detail: BeePluginMessageEditDetail, _version: number) => {
        if (!pristine) {
          setPristine(true);
          setSaved(false);
        }
      },
      hooks: {
        getRows: {
          handler: handleGetRows,
        },
      },
    };
  }, [
    accountId,
    closeSaveRow,
    handleGetRows,
    handleOnError,
    handleSaveRow,
    i18n.language,
    mergeTags,
    onRemoveRow,
    onSaveMail,
    onSaveTemplate,
    onSend,
    pristine,
    setDisabled,
    setPristine,
    setSaved,
    setShowPreview,
    signature,
    specialLinks,
    t,
    tags,
  ]);

  const start = useCallback(
    async (htmlJson: object | IEntityContentJson) => {
      if (!ref.current || !Array.isArray(tags)) {
        setTimeout(() => {
          void start(htmlJson);
        }, 1000);
        return;
      }

      if (document.querySelector('#bee-plugin-container')?.innerHTML !== '') return;

      await logger('viewAction', 'action');
      void ref.current.start(configuration, htmlJson);
      setInitialized(true);

      setTimeout(() => {
        if (!ref.current || document.querySelector('#bee-plugin-container')?.innerHTML !== '')
          return;
        void ref.current.start(configuration, htmlJson);
      }, 1000);
    },
    [tags, logger, configuration, setInitialized]
  );

  useEffect(() => {
    const init = async () => {
      const notHaveMergeTags = !Array.isArray(mergeTags) || mergeTags.length === 0;
      const notHaveSpecialLink = !Array.isArray(specialLinks) || specialLinks.length === 0;
      const notHaveMailContent = mailContent === null;

      if (notHaveMergeTags || notHaveSpecialLink || notHaveMailContent) return;

      !ref.current && (await getToken());
      if (initialized) return;

      if (mailContent?.contentHtmlEditable) {
        void start(mailContent?.contentHtmlEditable);
      } else {
        timer.current && clearTimeout(timer.current);
        timer.current = setTimeout(() => void init(), BEEFREE_INTERVAL_START);
      }
    };

    void init();
  }, [getToken, initialized, mailContent, mergeTags, setInitialized, specialLinks, start]);

  useEffect(() => {
    return () => {
      const prevent = getRedirectPrevent();
      if (prevent) {
        removePreventRedirect();
      }
      setPristine(false);
      const editor = document.querySelector('#bee-plugin-container');
      setMailContent(null);
      setInitialized(false);
      if (editor) editor.innerHTML = '';
    };
  }, [getRedirectPrevent, removePreventRedirect, setInitialized, setMailContent, setPristine]);

  useEffect(() => {
    if (!ref.current || !initialized) return;
    let timer: NodeJS.Timeout;
    const applyConfig = () => {
      if (timer) clearTimeout(timer);

      if (!ref?.current?.instance) {
        timer = setTimeout(applyConfig, 1000);
        return;
      }
      ref.current.loadConfig(configuration);
    };

    applyConfig();
  }, [configuration, initialized]);

  return {
    initialized,
    disabled,
    saving,
    saved,
    beefree: ref.current,
  };
};

export const useServicesEditor = ({
  actionId,
  accountId,
  listCustomField,
  tags,
  signature,
}: IUseServicesEditor) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const serviceBeefree = useBeefreeService();
  const { logger } = useBeefreeLogger();

  const [mailContent, setMailContent] = useAtom(atomEditorMail);
  const setShowModalTemplate = useSetAtom(atomModalTemplate);
  const setTemplateDataSave = useSetAtom(atomTemplateDataSave);
  const setBeefreeSaving = useSetAtom(atomBeefreeSaving);
  const setBeefreeSaved = useSetAtom(atomBeefreeSaved);
  const setPristine = useSetAtom(atomBeefreePristine);
  const setDisabled = useSetAtom(atomBeefreeDisabled);
  const newRulePath = useAtomValue(atomNewRulePath);

  const isAutomation = pathname.includes('automation');

  const { getRedirectPrevent, removePreventRedirect } = usePreventExit();

  const onSaveLinks = useCallback(
    async (links: ILinkTemplateV1[]) => {
      await logger('toSaveLinks', 'action', links);

      // cspell:disable
      const response = await serviceBeefree.saveTemplateLinks(actionId, [], links);

      if (response) {
        await logger('saveLinks', 'action', response);
      }

      // cspell:enable
      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionId, serviceBeefree]
  );

  const onErrorLinks = useCallback(
    async (links: ILinkTemplateV1[]): Promise<void> => {
      await logger('saveLinks', 'error', {
        links,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSaveMail: ISaveMail = useCallback(
    async (jsonFile: string, htmlFile: string): Promise<boolean> => {
      if (!Array.isArray(tags)) return false;

      setDisabled(true);
      setBeefreeSaving(true);

      const storageCreate = localStorage.getItem('@beefree/template.create');
      const createTemplate = storageCreate === 'true';

      await serviceBeefree.deleteCurrentLinks(actionId);

      const { template, json } = await TemplateAdapter(htmlFile, jsonFile, {
        listCustomField,
        tags,
        signature,
        onSaveLinks,
        onErrorLinks,
      });

      if (createTemplate) {
        setDisabled(true);
        setTemplateDataSave({
          jsonData: jsonFile,
          html: htmlFile,
        });
        return false;
      }

      const response = await serviceBeefree.saveEmail({
        contentCss: '',
        contentHtml: template,
        actionId: actionId,
        accountAutoId: accountId,
        contentHtmlEditable: json,
        defaultTemplateId: 1,
        type: 'beefree',
      });

      SendActionsPostMessage('Beefree', 'ClearActionCache');
      setBeefreeSaving(false);
      setPristine(false);
      setBeefreeSaved(true);
      setDisabled(false);

      setTimeout(() => {
        setBeefreeSaved(false);
      }, 2000);

      if (response?.isSaved) {
        toast({
          title: t('BEEFREE_SAVE.notify.success.title'),
          body: t('BEEFREE_SAVE.notify.success.body'),
          variant: 'success',
        });
        await logger('saveMail', 'action');
      }

      if (!response) {
        await logger('saveMail', 'error', {
          jsonFile,
        });

        toast({
          title: t('BEEFREE_SAVE.notify.error.title'),
          body: t('BEEFREE_SAVE.notify.error.body'),
          variant: 'success',
        });

        return false;
      }

      const urlRedirect = getRedirectPrevent();
      if (urlRedirect) {
        if (newRulePath && isAutomation) navigate(-1);
        else newRulePath ? navigate(`${urlRedirect}/email`) : navigate(urlRedirect);

        removePreventRedirect();
      }

      return true;
    },
    [
      tags,
      setDisabled,
      setBeefreeSaving,
      serviceBeefree,
      actionId,
      listCustomField,
      signature,
      newRulePath,
      isAutomation,
      onSaveLinks,
      onErrorLinks,
      accountId,
      setPristine,
      setBeefreeSaved,
      getRedirectPrevent,
      setTemplateDataSave,
      t,
      logger,
      navigate,
      removePreventRedirect,
    ]
  );

  const onSend = useCallback(() => {
    void serviceBeefree.sendEmail(actionId);
  }, [actionId, serviceBeefree]);

  const onSaveTemplate = useCallback(() => {
    setShowModalTemplate(true);
  }, [setShowModalTemplate]);

  const getEmailContent = useCallback(async () => {
    const response = await serviceBeefree.getEmail(actionId);

    try {
      response.contentHtmlEditable = JSON.parse(
        response.contentHtmlEditable as string
      ) as IEntityContentJson;
    } catch {
      response.contentHtmlEditable = {};
    }

    setMailContent(response as EditorMailData);
  }, [actionId, serviceBeefree, setMailContent]);

  const onSaveRow = useCallback(
    async (data: IRowSaveData, row: IPluginRow) => {
      const response = await serviceBeefree.saveRow({
        name: data.name,
        json: JSON.stringify(row),
        type: data.isEmblueRow ? IBeefreeRowType.EMBLUE : IBeefreeRowType.USER,
      });

      if (response?.error && response?.type === ISaveRowError.NAME_ALREADY_EXISTS) {
        toast({
          title: t('EDITOR_CONTENT.PREVIEW_LIVE.ERRORS.ALREADY_EXISTS.title'),
          body: t('EDITOR_CONTENT.PREVIEW_LIVE.ERRORS.ALREADY_EXISTS.body'),
          variant: 'error',
        });
        return false;
      } else if (!response?.error) {
        toast({
          title: t('EDITOR_CONTENT.PREVIEW_LIVE.SAVED.title'),
          body: t('EDITOR_CONTENT.PREVIEW_LIVE.SAVED.body'),
          variant: 'success',
        });
        return true;
      }
      return false;
    },
    [serviceBeefree, t]
  );

  const onRemoveRow = useCallback(
    async (guid: string) => {
      const response = await serviceBeefree.deleteRow(guid);
      if (response) {
        await logger('deleteRow', 'action', { guid });
      } else {
        await logger('deleteRow', 'error', { guid });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceBeefree]
  );

  useLayoutEffect(() => {
    if (mailContent === null) void getEmailContent();
  }, [getEmailContent, mailContent]);

  return {
    onSaveRow,
    onSaveMail,
    onSaveTemplate,
    onRemoveRow,
    mailContent,
    onSend,
  };
};
