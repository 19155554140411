import {
  ConnectionLineComponentProps,
  EdgeLabelRenderer,
  getSmoothStepPath,
  Node,
  useConnection,
} from '@xyflow/react';
import { FC } from 'react';

const ConnectionLine: FC<ConnectionLineComponentProps<Node>> = ({
  fromX,
  fromY,
  toX,
  toY,
  fromPosition,
  toPosition,
}) => {
  const { fromHandle } = useConnection();

  const [path, labelX, labelY] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
    sourcePosition: fromPosition,
    targetPosition: toPosition,
    borderRadius: 50,
  });

  let label: string | undefined = undefined;

  if (fromHandle?.id === 'yes') {
    label = 'Yes';
  } else if (fromHandle?.id === 'no') {
    label = 'No';
  }

  return (
    <g>
      <path className="animated" d={path} fill="none" stroke="#b1b1b7" strokeWidth={2} />
      {label && (
        <EdgeLabelRenderer>
          <div
            className="nodrag nopan"
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              fontSize: 12,
              padding: '4px 8px',
              background: '#364365',
              border: '1px solid #b1b1b7',
              borderRadius: 4,
              whiteSpace: 'nowrap',
              pointerEvents: 'all',
              color: 'white',
            }}
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </g>
  );
};

export default ConnectionLine;
