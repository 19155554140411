import { TrashIcon } from '@heroicons/react/outline';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from '@xyflow/react';
import { FC, memo, MouseEvent, MouseEventHandler } from 'react';

export interface CustomEdgeProps extends EdgeProps {
  onDelete?: (edgeId: string) => void;
}

const CustomEdge: FC<CustomEdgeProps> = (props) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    data,
    label,
    onDelete,
  } = props;
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 50,
  });

  const handleRemoveEdge = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete?.(id);
  };

  return (
    <g className="group">
      <BaseEdge id={id} markerEnd={markerEnd} path={edgePath} style={{ strokeWidth: 2 }} />(
      <EdgeLabelRenderer>
        <div
          className="nodrag nopan flex flex-row items-center justify-center gap-1"
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            fontSize: 12,
          }}
        >
          {label === 'No' && <DeleteButton onClick={handleRemoveEdge} />}
          {label && (
            <span
              style={{
                fontSize: 12,
                padding: '4px 8px',
                background: '#364365',
                border: '1px solid #D3D3D3',
                borderRadius: 4,
                whiteSpace: 'nowrap',
                pointerEvents: 'all',
                color: 'white',
              }}
            >
              {label}
            </span>
          )}
          {(label === 'Yes' || !label) && <DeleteButton onClick={handleRemoveEdge} />}
        </div>
      </EdgeLabelRenderer>
      )
    </g>
  );
};

export default memo(CustomEdge);

interface DeleteButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <button
      className="rounded-full border-2 border-[#b1b1b7] bg-white p-0.5 text-emblue-alert hover:text-red-600"
      style={{ pointerEvents: 'all' }}
      onClick={onClick}
    >
      <TrashIcon className="size-4" />
    </button>
  );
};
