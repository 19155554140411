import { TrashIcon } from '@heroicons/react/outline';
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { FC, isValidElement, KeyboardEvent, memo, MouseEvent, ReactNode, useCallback } from 'react';

import { IconSvg, Text } from '@/lib/v2/components';

export const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  borderWidth: 2,
  borderColor: '#b1b1b7',
  background: 'white',
};

type BorderColor = 'default' | 'trigger' | 'action' | 'condition';
export interface CommonFlowCardProps {
  title?: string;
  info?: string | ReactNode;
  icon?: ReactNode;
  borderColor?: BorderColor;
  editAction?: {
    label?: string;
    onClick: (nodeId: string | number) => void;
  };
  noTargetHandle?: boolean;
  id?: string;
  onDelete?: (nodeId: string) => void;
}

const CommonFlowCard: FC<CommonFlowCardProps> = ({
  title,
  icon,
  info,
  borderColor = 'default',
  editAction,
  noTargetHandle = false,
  id,
  onDelete,
}) => {
  const classesContainer = classNames(
    'group w-[280px] max-w-[280px] rounded-lg border bg-emblue-white overflow-hidden'
  );

  const classesBorder = classNames('h-2 w-full', {
    'bg-emblue-black': borderColor === 'default',
    'bg-emblue-success': borderColor === 'trigger',
    'bg-emblue-primary': borderColor === 'action',
    'bg-[#9747FF]': borderColor === 'condition',
  });

  const handleDeleteNode = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onDelete?.(id ?? '');
    },
    [id, onDelete]
  );

  const handleEditNode = useCallback(
    (
      event:
        | MouseEvent<HTMLDivElement>
        | MouseEvent<HTMLSpanElement>
        | KeyboardEvent<HTMLDivElement>
    ) => {
      event.stopPropagation();
      editAction?.onClick?.(id ?? '');
    },
    [editAction, id]
  );

  const RenderInfo = () => {
    if (typeof info === 'string') {
      return (
        <Text className="min-w-[169px] overflow-hidden" variant="text-sm">
          {info}
        </Text>
      );
    }
    if (isValidElement(info)) {
      return info;
    } else {
      return null;
    }
  };

  return (
    <div
      className={classesContainer}
      id={id}
      role="button"
      style={{ boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)' }}
      tabIndex={0}
      onClick={info ? handleEditNode : undefined}
      onKeyDown={(e) => {
        if (info && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault();
          handleEditNode(e);
        }
      }}
    >
      {!noTargetHandle && (
        <Handle position={Position.Top} style={{ ...DEFAULT_HANDLE_STYLE }} type="target" />
      )}
      <div className={classesBorder} />
      {onDelete && (
        <button
          className="invisible absolute -right-2 -top-2 rounded-full border-2 border-[#b1b1b7] bg-white p-0.5 text-emblue-alert opacity-0 transition-all
          duration-200 hover:text-red-600 group-hover:visible group-hover:opacity-100"
          onClick={handleDeleteNode}
        >
          <TrashIcon className="size-4" />
        </button>
      )}
      <div className="flex w-full items-center justify-between gap-4 overflow-hidden px-6 pb-6 pt-4">
        {icon && <IconSvg className="flex-none" height="35px" svgComponent={icon} width="35px" />}
        <div className="flex w-full flex-col">
          <Text fontWeight="medium" variant="text">
            {title}
          </Text>
          {info && <RenderInfo />}
          {!info && (
            <Text
              link
              className="w-fit"
              color="primary"
              variant="text-sm"
              onClick={(e) => handleEditNode(e as MouseEvent<HTMLSpanElement>)}
            >
              {editAction?.label ?? 'Click to edit'}
            </Text>
          )}
        </div>
      </div>
      <Handle position={Position.Bottom} style={{ ...DEFAULT_HANDLE_STYLE }} type="source" />
    </div>
  );
};

export default memo(CommonFlowCard);
