import { memo } from 'react';

import { HtmlPreview } from './components/HtmlPreview';
import { SelectorPreview } from './components/SelectorPreview';

import { useActionPreview } from '@/modules/CampaignsModule/components/actionPreview/ActionPreviewEmail/hooks/useActionPreview';

const ActionPreviewEmail = () => {
  const { emailPreview, htmlString, loading, previewContactId } = useActionPreview();
  const { sender, subject } = emailPreview || {};

  return (
    <div className="px-10 py-3">
      <SelectorPreview disabled={loading} />
      <HtmlPreview
        emailFrom={sender?.emailFrom}
        htmlString={htmlString}
        loading={loading || previewContactId === undefined || !emailPreview?.content}
        senderName={sender?.nameFrom}
        subject={subject}
      />
    </div>
  );
};

export default memo(ActionPreviewEmail);
