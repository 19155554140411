import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, IconSvg, Select, Text, Tooltip } from '@/lib/v2/components';
import { Warning2Icon } from '@/lib/v2/icons/outline';
import { ExclamationIcon } from '@/lib/v2/icons/solid';

import { ContactTestTooltip } from '@/src/modules/CampaignsModule/components/actionPreview/ActionPreviewEmail/components/ContactTestTooltip';
import { GroupTestTooltip } from '@/src/modules/CampaignsModule/components/actionPreview/ActionPreviewEmail/components/GroupTestTooltip';

import { useSelectorPreview } from './useSelectorPreview.hooks';

import EmailPreviewButtons from '@/modules/CampaignsModule/components/EmailPreviewButtons/EmailPreviewButtons';
import { useEmailPreviewButtons } from '@/modules/CampaignsModule/components/EmailPreviewButtons/useEmailPreviewButtons.hooks';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { isActionEditable } from '@/modules/CampaignsModule/utils';

const SelectorPreview = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const { action } = useActionData(Number(actionId));
  const {
    events: { handleSendTest, handleChangeGroup, handleChangeContact },
    state: {
      loadingData,
      previewContacts,
      previewContactSelected,
      previewGroups,
      previewGroupSelected,
      isButtonDisabled,
      sendingEmailTest,
      subject,
      sourceEditor,
      message,
    },
  } = useSelectorPreview();

  const {
    state: { loadingDelete },
    events: { handleDeleteMessage },
  } = useEmailPreviewButtons({});

  const isEditable = useMemo(() => {
    return isActionEditable(Number(action?.status)) && !loadingData;
  }, [action, loadingData]);

  return (
    <section className="pt-2">
      <div className="flex items-end justify-between">
        <div className="flex gap-2">
          <div className="w-72">
            <div className="flex items-center gap-2">
              <Text fontWeight="normal" variant="text">
                {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.viewAsContacts')}
              </Text>
              <Tooltip isClickable content={<ContactTestTooltip />} position="right">
                <IconSvg
                  className="mb-1"
                  height="16px"
                  svgComponent={<ExclamationIcon />}
                  width="16px"
                />
              </Tooltip>
            </div>
            <Select
              id="preview__select-contact"
              isLoading={loadingData}
              options={previewContacts || []}
              placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.selectContact')}
              value={previewContactSelected}
              onChange={handleChangeContact}
            />
          </div>
          <div>
            <div className="flex items-center gap-2">
              <Text fontWeight="normal" variant="text">
                {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.groupTest')}
              </Text>
              <Tooltip isClickable content={<GroupTestTooltip />} position="right">
                <IconSvg
                  className="mb-1"
                  height="16px"
                  svgComponent={<ExclamationIcon />}
                  width="16px"
                />
              </Tooltip>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-72">
                <Select
                  id="preview__select-group"
                  isLoading={loadingData}
                  options={previewGroups || []}
                  placeholder={t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.selectGroup')}
                  value={previewGroupSelected}
                  onChange={handleChangeGroup}
                />
              </div>
              <div className="flex items-center gap-2">
                <Button
                  disabled={isButtonDisabled}
                  id="preview__send-test-email"
                  isLoading={sendingEmailTest}
                  onClick={handleSendTest}
                >
                  {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.send')}
                </Button>
                {!subject && !loadingData && (
                  <div className="flex items-center gap-1">
                    <IconSvg
                      className="mt-0.5 min-w-[20px]"
                      fillColor={'alert'}
                      height="20px"
                      svgComponent={<Warning2Icon />}
                      width="20px"
                    />
                    <Text fontWeight="normal" variant="text">
                      {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.sendButtonTooltip')}
                    </Text>
                  </div>
                )}
                {subject && !loadingData && previewContacts?.length === 0 && (
                  <div className="flex max-w-[340px] items-center gap-1">
                    <IconSvg
                      className="mt-0.5 min-w-[20px]"
                      fillColor={'alert'}
                      height="20px"
                      svgComponent={<Warning2Icon />}
                      width="20px"
                    />
                    <Text className="leading-tight" fontWeight="normal" variant="text">
                      {t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.warningContactTest')}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <EmailPreviewButtons
            disabled={disabled || !isEditable || !message}
            hasPreview={disabled}
            loading={loadingData || loadingDelete}
            sourceEditor={sourceEditor}
            onDelete={handleDeleteMessage}
          />
        </div>
      </div>
    </section>
  );
};

export default memo(SelectorPreview);
